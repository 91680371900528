export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-chart-line',
        permission: 'dashboard',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Finances',
        icon: 'cil-dollar',
        permission: 'finance',
        items: [
          {
            name: 'Revenue',
            to: '/revenue',
          },
          {
            name: 'Invoices',
            to: '/invoices',
          },
          // {
          //   name: 'SPs owed',
          //   to: '/sp_owed',
          // },
          {
            name: 'SPs balance outstanding',
            to: '/sp_balance_outstanding',
          },
          {
            name: 'SPs fee owed',
            to: '/sp_fee_owed',
          },
        ],
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consumers',
        to: '/consumers',
        icon: 'cil-user',
        permission: 'consumer',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Providers',
        icon: 'cil-puzzle',
        permission: 'provider',
        items: [
          {
            name: 'SP accounts',
            to: '/sp/accounts',
          },
          {
            name: 'SP applications',
            to: '/sp/applications',
          },
          {
            name: 'Expiring insurances',
            to: '/sp/insurances',
          },
          {
            name: 'Request new categories',
            to: '/sp/request_new_categories',
          },
        ],
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Providers',
      //   to: '/providers',
      //   icon: 'cil-puzzle',
      //   permission: 'provider',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Services',
      //   to: '/services',
      //   icon: 'cil-star',
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Bookings',
        to: '/bookings',
        icon: 'cil-chart-pie',
        permission: 'booking',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Announcements',
        to: '/announcements',
        icon: 'cil-bell',
        permission: 'announcement',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'User Internals',
        to: '/user-internals',
        permission: 'user_internal',
        icon: 'cil-user',
      },
      {
        _name: 'CSidebarNavItem',
        icon: 'cil-tags',
        name: 'Categories',
        to: '/categories',
        permission: 'category',
      },
    ],
  },
];
