<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('sidebar/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="menu" />

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('sidebar/set', ['sidebarMinimize', !minimize])
      "
    />
  </CSidebar>
</template>

<script>
import nav from './_nav';
import { mapGetters } from 'vuex';
import { ACTION_FETCH_PROFILE } from '@/store/actions.type';

export default {
  name: 'TheSidebar',
  // nav,
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_PROFILE);
  },
  computed: {
    menu() {
      //build menu
      let children = [];
      let { _name, _children } = nav[0];
      _children.forEach((x) => {
        if (
          (x.permission && this.permissions.includes(x.permission)) ||
          x.permission == 'dashboard'
        ) {
          children.push(x);
        }
        // if (x.items?.length > 0) {
        //   x.items = x.items.filter((y) => {
        //     return this.permissions.includes(y.permission);
        //   });
        //   children.push(x);
        // }
      });
      return [
        {
          _name,
          _children: children,
        },
      ];
    },
    ...mapGetters({
      permissions: 'permissions',
    }),
    show() {
      return this.$store.state.sidebar.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebar.sidebarMinimize;
    },
  },
  beforeDestroy() {},
  created() {},
  methods: {},
};
</script>
